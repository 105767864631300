/* muli-200normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Muli Extra Light '),
    local('Muli-Extra Light'),
    url(/static/media/muli-latin-200.1dfc66c7.woff2) format('woff2'), 
    url(/static/media/muli-latin-200.b79246db.woff) format('woff'); /* Modern Browsers */
}

/* muli-200italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Muli Extra Light italic'),
    local('Muli-Extra Lightitalic'),
    url(/static/media/muli-latin-200italic.ed5f4d70.woff2) format('woff2'), 
    url(/static/media/muli-latin-200italic.787c91cf.woff) format('woff'); /* Modern Browsers */
}

/* muli-300normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Muli Light '),
    local('Muli-Light'),
    url(/static/media/muli-latin-300.73d23299.woff2) format('woff2'), 
    url(/static/media/muli-latin-300.6e03d5eb.woff) format('woff'); /* Modern Browsers */
}

/* muli-300italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Muli Light italic'),
    local('Muli-Lightitalic'),
    url(/static/media/muli-latin-300italic.49ef162a.woff2) format('woff2'), 
    url(/static/media/muli-latin-300italic.25532f4b.woff) format('woff'); /* Modern Browsers */
}

/* muli-400normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Muli Regular '),
    local('Muli-Regular'),
    url(/static/media/muli-latin-400.8160dac0.woff2) format('woff2'), 
    url(/static/media/muli-latin-400.b0f17b38.woff) format('woff'); /* Modern Browsers */
}

/* muli-400italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Muli Regular italic'),
    local('Muli-Regularitalic'),
    url(/static/media/muli-latin-400italic.730c6bfa.woff2) format('woff2'), 
    url(/static/media/muli-latin-400italic.688fb50a.woff) format('woff'); /* Modern Browsers */
}

/* muli-600normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Muli SemiBold '),
    local('Muli-SemiBold'),
    url(/static/media/muli-latin-600.4273185a.woff2) format('woff2'), 
    url(/static/media/muli-latin-600.0f2fbd56.woff) format('woff'); /* Modern Browsers */
}

/* muli-600italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Muli SemiBold italic'),
    local('Muli-SemiBolditalic'),
    url(/static/media/muli-latin-600italic.7567e136.woff2) format('woff2'), 
    url(/static/media/muli-latin-600italic.9897cb2b.woff) format('woff'); /* Modern Browsers */
}

/* muli-700normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Muli Bold '),
    local('Muli-Bold'),
    url(/static/media/muli-latin-700.d3703a0d.woff2) format('woff2'), 
    url(/static/media/muli-latin-700.a6600f60.woff) format('woff'); /* Modern Browsers */
}

/* muli-700italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Muli Bold italic'),
    local('Muli-Bolditalic'),
    url(/static/media/muli-latin-700italic.af763a17.woff2) format('woff2'), 
    url(/static/media/muli-latin-700italic.00211c4a.woff) format('woff'); /* Modern Browsers */
}

/* muli-800normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Muli ExtraBold '),
    local('Muli-ExtraBold'),
    url(/static/media/muli-latin-800.ab72c9c1.woff2) format('woff2'), 
    url(/static/media/muli-latin-800.47fd62e6.woff) format('woff'); /* Modern Browsers */
}

/* muli-800italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Muli ExtraBold italic'),
    local('Muli-ExtraBolditalic'),
    url(/static/media/muli-latin-800italic.33637beb.woff2) format('woff2'), 
    url(/static/media/muli-latin-800italic.a1e64515.woff) format('woff'); /* Modern Browsers */
}

/* muli-900normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Muli Black '),
    local('Muli-Black'),
    url(/static/media/muli-latin-900.37700966.woff2) format('woff2'), 
    url(/static/media/muli-latin-900.66c9d5ed.woff) format('woff'); /* Modern Browsers */
}

/* muli-900italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Muli Black italic'),
    local('Muli-Blackitalic'),
    url(/static/media/muli-latin-900italic.f99056b6.woff2) format('woff2'), 
    url(/static/media/muli-latin-900italic.3b4a9a0e.woff) format('woff'); /* Modern Browsers */
}


